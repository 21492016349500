import React from "react";
import CareBear from "../Assets/CareBear.webp"

function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center p-5 mt-5">
            <h3>The Competition</h3>
          </div>
          <div className="col-12 text-center px-5">
            <p>
              Well Dennys is almost IHOP but theres one slight difference. IHOP is the 
              "International House of Pancakes" and well Dennys is well just a Diner that
              serves pancakes. Is Dennys bad well thats entirely up to you to figure out. 
              For me its alright.
            </p>
            <img src={CareBear} className="img p-5" alt="Carbear"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;