import React from "react";
import Interior from "../Assets/Interior.jpg";
import Pancakes from "../Assets/pancakes.jpg";
function Home() {
  return (
    <div className="home">
        <div class="container">
            <div className="row">
                <div className="col-12 text-center p-5">
                    <h1>A IHOP "fansite".</h1>
                </div>
                <div className="col-12 col-lg-6 text-center p-5">
                    <p>If you dont like IHOP then we dont need you around these parts.
                        Because this is the only place where you can get a decent meal
                        in all the land. I mean really anywhere you gotta drive 60 miles
                        to get to the IHOP but my goodness is it worth it.
                    </p>
                    <img src={Interior} className="img d-none d-sm-block mx-auto p-3" alt="interior of IHOP"></img>
                </div>
                <div className="col-12 col-lg-6 text-center p-5">
                    <img src={Pancakes} className="img d-none d-sm-block mx-auto p-3" alt="pancakes"></img>
                    <p>If you dont like fluffy pancakes well your in the wrong place because
                        thats the best item they serve. You should know this by now its
                        called the international house of pancakes like come on y'all.
                    </p>
                </div>                           
            </div>
      </div>
    </div>
  );
}

export default Home;