import React from "react";
import Ven  from "../Assets/Ven.png"
function Projects() {
  return (
      <div class="container-fluid">
        <div class="row">
        <div className="col-12 p-5">
                    <img src={Ven} id="ven" className="d-sm-block mx-auto " alt="pancakes"></img>
                </div> 
        </div>
      </div>
  );
}

export default Projects;